import React, { Suspense, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { NavLink as NavLinkBase, Outlet, useNavigate } from 'react-router-dom';
import {
  NavigationItem,
  navigationItems,
  useNavigation,
} from '../../utils/navigation';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  ListItemSecondaryAction,
  MenuList,
  Paper,
  useMediaQuery,
} from '@mui/material';
import logo from '../../images/marvin-logo-white.svg';
import { useTranslation } from 'react-i18next';
import AlertSnackbar from './AlertSnackbar';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlertAction } from '../app/appSlice';
import { hasPermission } from '../../auth';
import { RootState } from '../../store';
import FullscreenButton from './FullscreenButton';
import UpdateDialog from './UpdateDialog';
import {
  ArrowBackIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  MenuIcon,
} from './icons';
import { usePersistedState, useQueryParams } from '../app/utils';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LARGE_SCREEN_NAV_COLLAPSED } from '../profile/types';
import EditIconButton from './EditIconButton';
import HelpLinkIcon from '../app/components/HelpLinkIcon';
import UserMenu from '../app/components/UserMenu';

const DRAWER_WIDTH = 275;
const PREFIX = 'DrawerContents';

const styles = {
  logo: `${PREFIX}-logo`,
  listButton: `${PREFIX}-listButton`,
  selected: `${PREFIX}-selected`,
  appBar: `${PREFIX}-appBar`,
};
const StyledDrawerContentsDiv = styled('div')(({ theme }) => ({
  [`& .${styles.logo}`]: {
    cursor: 'pointer',
    marginLeft: theme.spacing(5.5),
  },
  [`& .${styles.listButton}`]: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  [`& .${styles.selected}`]: {
    background: 'rgba(0, 0, 0, 0.1)',
    color: 'black',
    boxShadow: `inset 6px 0 0 ${theme.palette.primary.main}`,
  },
}));
const NAV_PREFIX = 'Navigation';
const navigationStyles = {
  content: `${NAV_PREFIX}-content`,
};

const StyledContentDiv = styled('div', { label: 'StyledContent' })(
  ({ theme }) => ({
    height: '100%',
    display: 'flex',
    [`& .${navigationStyles.content}`]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
    },
  }),
);
const NavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLinkBase
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ''}`
    }
  >
    {props.children}
  </NavLinkBase>
));

/**
 * Renders the navigation and app shell
 * @constructor
 */
const Navigation = () => {
  const { fullscreen: fullscreenParam }: { fullscreen: string } =
    useQueryParams();
  const isFullscreen =
    useSelector((state: RootState) => state.app.isFullscreen) ||
    fullscreenParam;
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { navigateBack } = useNavigation();
  const alertOpen = useSelector((state: RootState) => state.app.alertOpen);
  const alertMessageKey = useSelector(
    (state: RootState) => state.app.alertMessageKey,
  );
  const alertLevel = useSelector((state: RootState) => state.app.alertLevel);
  const [expandState, setExpandState] = useState<{ [key in string]?: boolean }>(
    {},
  );
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [temporaryNavOpen, setTemporaryNavOpen] = useState(false);
  const [largeScreenNavCollapsed, setLargeScreenNavCollapsed] =
    usePersistedState(LARGE_SCREEN_NAV_COLLAPSED, false);

  const userSetting = useSelector(
    (state: RootState) => state.profile.userSetting,
  );
  const enabledNavigationItems = navigationItems
    .filter(isEnabled)
    .filter(hasPermissionFilter)
    .map((n) => ({
      ...n,
      children:
        n.children && n.children.length > 0
          ? n.children.filter(isEnabled).filter(hasPermissionFilter)
          : [],
    }));

  /**
   * Filter that determines if navigation item is enabled in settings
   * @param n
   */
  function isEnabled(n: NavigationItem) {
    return n.enabledKey
      ? JSON.parse(userSetting[n.enabledKey] || 'false')
      : true;
  }
  /**
   * Filter that determines current user has permission required for navigation item
   * @param n
   */
  function hasPermissionFilter(n: NavigationItem) {
    return n.permissions && n.permissions.length > 0
      ? hasPermission(n.permissions)
      : true;
  }
  /**
   * Toggles the navigation drawer
   */
  const handleTemporaryDrawerToggle = () =>
    setTemporaryNavOpen(!temporaryNavOpen);

  /**
   * Toggles the second drawer for larger screens
   */
  const handlePersistentDrawerToggle = () => {
    setLargeScreenNavCollapsed(!largeScreenNavCollapsed);
  };

  /**
   * Closes the temporary navigation drawer if open
   */
  const handleTemporaryDrawerClose = () => setTemporaryNavOpen(false);
  /**
   * Toggles the expand state of a navigation item
   * @param key
   */
  function handleItemExpandToggle(key: string) {
    setExpandState({
      ...expandState,
      [key]: !expandState[key],
    });
  }
  const navigate = useNavigate();
  const drawerContents = (
    <StyledDrawerContentsDiv>
      <Box
        sx={(theme) => ({
          ...theme.mixins.toolbar,
          display: 'flex',
          alignItems: 'center',
          bgcolor: theme.palette.secondary.main,
          minHeight: 64,
        })}
      >
        <img
          src={logo}
          alt='logo'
          className={styles.logo}
          onClick={() => navigate('/')}
        />
        <EditIconButton
          key={`edit-icon-button-${
            largeScreenNavCollapsed ? 'visible' : 'hidden'
          }`}
          tooltip={t('hide')}
          onClick={handlePersistentDrawerToggle}
          sx={{
            ml: 2,
            display: 'none',
            [theme.breakpoints.up('lg')]: {
              display: isFullscreen ? 'none' : 'inline-flex',
            },
            color: 'white',
          }}
          icon={<ChevronLeftIcon />}
        />
      </Box>
      <Divider />
      <List sx={{ pt: 0 }}>
        {enabledNavigationItems.map((n) => (
          <React.Fragment key={n.labelKey}>
            <ListItem
              sx={{ width: DRAWER_WIDTH }}
              button
              component={NavLink}
              to={n.path}
              activeClassName={styles.selected}
              classes={{ button: styles.listButton }}
              onClick={handleTemporaryDrawerClose}
            >
              <ListItemIcon sx={{ color: 'secondary.main', minWidth: 44 }}>
                {<n.icon />}
              </ListItemIcon>
              <ListItemText primary={t(n.labelKey)} />
              {n.children && n.children.length > 0 && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge={'end'}
                    onClick={() => handleItemExpandToggle(n.labelKey)}
                    size='large'
                  >
                    {expandState[n.labelKey] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            {n.children && n.children.length > 0 && (
              <Collapse
                in={expandState[n.labelKey]}
                timeout={'auto'}
                unmountOnExit
              >
                {n.children.map((nc) => (
                  <ListItem
                    key={nc.labelKey}
                    button
                    component={NavLink}
                    to={nc.path}
                    activeClassName={styles.selected}
                    classes={{ button: styles.listButton }}
                    onClick={handleTemporaryDrawerClose}
                  >
                    <ListItemIcon sx={{ ml: 3, minWidth: 44 }}>
                      {<nc.icon />}
                    </ListItemIcon>
                    <ListItemText primary={t(nc.labelKey)} />
                  </ListItem>
                ))}
              </Collapse>
            )}
          </React.Fragment>
        ))}
        <UserMenu />
      </List>
    </StyledDrawerContentsDiv>
  );
  return (
    <StyledContentDiv>
      <AppBar
        position='fixed'
        sx={{
          boxShadow: 'none',
          [theme.breakpoints.up('lg')]: {
            width:
              isFullscreen || largeScreenNavCollapsed
                ? '100%'
                : `calc(100% - ${DRAWER_WIDTH}px)`,
          },
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
          }}
        >
          <EditIconButton
            tooltip={t('menu')}
            onClick={
              isLargeScreen && !isFullscreen
                ? handlePersistentDrawerToggle
                : handleTemporaryDrawerToggle
            }
            sx={{
              color: 'inherit',
              mr: 1,
              ml: -1,
              [theme.breakpoints.up('lg')]: {
                display:
                  isFullscreen || largeScreenNavCollapsed
                    ? 'inline-flex'
                    : 'none',
              },
            }}
            icon={<MenuIcon />}
          />
          <EditIconButton
            tooltip={t('back')}
            onClick={navigateBack}
            sx={{ color: 'inherit', ml: -1 }}
            icon={<ArrowBackIcon />}
          />
          <Typography
            variant='h6'
            noWrap
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            {t('cellCommunicationCenter')}
          </Typography>
          <HelpLinkIcon />
          <FullscreenButton sx={{ color: 'inherit', mr: 0.25 }} />
        </Toolbar>
      </AppBar>
      <MenuList
        sx={{
          [theme.breakpoints.up('lg')]: {
            width: isFullscreen || largeScreenNavCollapsed ? 0 : DRAWER_WIDTH,
            flexShrink: 1,
          },
          display: isFullscreen || largeScreenNavCollapsed ? 'none' : 'block',
        }}
      >
        <Paper>
          <Drawer
            sx={{
              [theme.breakpoints.up('lg')]: {
                width: isFullscreen ? 0 : DRAWER_WIDTH,
                flexShrink: 0,
              },
            }}
            variant='temporary'
            anchor='left'
            open={temporaryNavOpen}
            onClose={handleTemporaryDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContents}
          </Drawer>
        </Paper>
        <Paper
          sx={{ display: isLargeScreen && !isFullscreen ? 'block' : 'none' }}
        >
          <Drawer
            sx={{
              width: largeScreenNavCollapsed ? 0 : DRAWER_WIDTH,
              flexShrink: 0,
            }}
            variant='persistent'
            anchor='left'
            open={!largeScreenNavCollapsed}
            onClose={handlePersistentDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawerContents}
          </Drawer>
        </Paper>
      </MenuList>

      <div className={navigationStyles.content}>
        <Box sx={(theme) => theme.mixins.toolbar} />
        <Suspense fallback={<CircularProgress />}>
          <Outlet />
        </Suspense>
      </div>
      <AlertSnackbar
        open={alertOpen}
        onClose={() => dispatch(clearAlertAction())}
        message={t(alertMessageKey)}
        alertLevel={alertLevel}
      />
      <UpdateDialog />
    </StyledContentDiv>
  );
};
export default Navigation;
