export type Account = {
  name: string;
  email: string;
};

export type SetUserSetting = {
  key: UserSettingKey;
  value: string | undefined;
};
export type User = {
  id: string;
  name: string;
  username: string;
  email: string;
  employeeId: string;
  jiraAccountId: string;
};

export const BANNER_LEVEL = 'BANNER_LEVEL';
export const BANNER_MESSAGE = 'BANNER_MESSAGE';
export const BANNER_LOCATION = 'BANNER_LOCATION';
export const CELL_BOARD_DANGEROUS_EQUIPMENT_TABLE_PAGE_SIZE =
  'CELL_BOARD_DANGEROUS_EQUIPMENT_TABLE_PAGE_SIZE';
export const CELL_BOARD_DANGEROUS_EQUIPMENT_TABLE_SORTING =
  'CELL_BOARD_DANGEROUS_EQUIPMENT_TABLE_SORTING';

export const CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_COLUMN_ORDER =
  'CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_COLUMN_ORDER';
export const CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_SORTING =
  'CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_SORTING';

export const CELL_BOARD_HOUR_BY_HOUR_BAR_CHART_ACCORDION_EXPANDED =
  'CELL_BOARD_HOUR_BY_HOUR_BAR_CHART_ACCORDION_EXPANDED';
export const CELL_BOARD_HOUR_BY_HOUR_SHIFT_SELECTED =
  'CELL_BOARD_HOUR_BY_HOUR_SHIFT_SELECTED';

export const CELL_BOARD_M_CELL_COLUMN_ORDER = 'CELL_BOARD_M_CELL_COLUMN_ORDER';
export const CELL_BOARD_ENABLED = 'CELL_BOARD_ENABLED';

export const CELL_BOARD_INVENTORY_CENTER_ID = 'CELL_BOARD_INVENTORY_CENTER_ID';
export const CELL_BOARD_MANUFACTURING_LOCATION =
  'CELL_BOARD_MANUFACTURING_LOCATION';

export const CELL_BOARD_M_CELL_COLUMN_WIDTHS =
  'CELL_BOARD_M_CELL_COLUMN_WIDTHS';
export const CELL_BOARD_M_CELL_CONFIG_PAGE_SIZE =
  'CELL_BOARD_M_CELL_CONFIG_PAGE_SIZE';
export const CELL_BOARD_M_CELL_CONFIG_SORTING =
  'CELL_BOARD_M_CELL_CONFIG_SORTING';
export const CELL_BOARD_M_CELL_ID = 'CELL_BOARD_M_CELL_ID';
export const CELL_BOARD_M_CELL_COLLECTION_ID =
  'CELL_BOARD_M_CELL_COLLECTION_ID';
export const CELL_BOARD_M_CELL_TYPE_SELECTED =
  'CELL_BOARD_M_CELL_TYPE_SELECTED';

export const CELL_BOARD_MESSAGE_GLOBAL_MODE = 'CELL_BOARD_MESSAGE_GLOBAL_MODE';

export const CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_COLUMN_ORDER =
  'CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_COLUMN_ORDER';
export const CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_PAGE_SIZE =
  'CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_PAGE_SIZE';
export const CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_SORTING =
  'CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_SORTING';

export const CELL_BOARD_MESSAGE_TABLE_COLUMN_ORDER =
  'CELL_BOARD_MESSAGE_TABLE_COLUMN_ORDER';
export const CELL_BOARD_MESSAGE_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_MESSAGE_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_MESSAGE_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_MESSAGE_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_MESSAGE_TABLE_PAGE_SIZE =
  'CELL_BOARD_MESSAGE_TABLE_PAGE_SIZE';
export const CELL_BOARD_MESSAGE_TABLE_SORTING =
  'CELL_BOARD_MESSAGE_TABLE_SORTING';

export const CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_COLUMN_ORDER =
  'CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_COLUMN_ORDER';
export const CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_PAGE_SIZE =
  'CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_PAGE_SIZE';
export const CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_SORTING =
  'CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_SORTING';

export const CELL_BOARD_M_CELL_TASK_REPORT_TABLE_COLUMN_ORDER =
  'CELL_BOARD_M_CELL_TASK_REPORT_TABLE_COLUMN_ORDER';
export const CELL_BOARD_M_CELL_TASK_REPORT_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_M_CELL_TASK_REPORT_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_M_CELL_TASK_REPORT_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_M_CELL_TASK_REPORT_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_M_CELL_TASK_REPORT_TABLE_PAGE_SIZE =
  'CELL_BOARD_M_CELL_TASK_REPORT_TABLE_PAGE_SIZE';
export const CELL_BOARD_M_CELL_TASK_REPORT_TABLE_SORTING =
  'CELL_BOARD_M_CELL_TASK_REPORT_TABLE_SORTING';

export const CELL_BOARD_PERSONAL_PROTECTIVE_EQUIPMENT_TABLE_PAGE_SIZE =
  'CELL_BOARD_PERSONAL_PROTECTIVE_EQUIPMENT_TABLE_PAGE_SIZE';
export const CELL_BOARD_PERSONAL_PROTECTIVE_EQUIPMENT_TABLE_SORTING =
  'CELL_BOARD_PERSONAL_PROTECTIVE_EQUIPMENT_TABLE_SORTING';

export const CELL_BOARD_PRODUCTION_DETAIL_ACCORDION_EXPANDED =
  'CELL_BOARD_PRODUCTION_DETAIL_ACCORDION_EXPANDED';

export const CELL_BOARD_REPORT_MANUFACTURING_LOCATION =
  'CELL_BOARD_REPORT_MANUFACTURING_LOCATION';
export const CELL_BOARD_REPORT_SHIFTS = 'CELL_BOARD_REPORT_SHIFTS';

export const CELL_BOARD_ROSTER_TABLE_COLUMN_ORDER =
  'CELL_BOARD_ROSTER_TABLE_COLUMN_ORDER';
export const CELL_BOARD_ROSTER_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_ROSTER_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_ROSTER_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_ROSTER_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_ROSTER_TABLE_SORTING =
  'CELL_BOARD_ROSTER_TABLE_SORTING';

export const CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_COLUMN_ORDER =
  'CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_COLUMN_ORDER';
export const CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_SORTING =
  'CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_SORTING';

export const CELL_BOARD_SLIDESHOW_INTERVAL_ACTIVE =
  'CELL_BOARD_SLIDESHOW_INTERVAL_ACTIVE';
export const CELL_BOARD_SCORECARD_SLIDESHOW_ACTIVE =
  'CELL_BOARD_SCORECARD_SLIDESHOW_ACTIVE';
export const CELL_BOARD_SHIFT_SELECTED = 'CELL_BOARD_SHIFT_SELECTED';
export const CELL_BOARD_SHIFTS_SELECTED = 'CELL_BOARD_SHIFTS_SELECTED';
export const CELL_BOARD_SHOW_EMPTY = 'CELL_BOARD_SHOW_EMPTY';

export const CELL_BOARD_SAFETY_MESSAGE_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_SAFETY_MESSAGE_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_SAFETY_MESSAGE_TABLE_PAGE_SIZE =
  'CELL_BOARD_SAFETY_MESSAGE_TABLE_PAGE_SIZE';
export const CELL_BOARD_SAFETY_MESSAGE_TABLE_SORTING =
  'CELL_BOARD_SAFETY_MESSAGE_TABLE_SORTING';
export const CELL_BOARD_SAFETY_MESSAGE_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_SAFETY_MESSAGE_TABLE_COLUMN_WIDTHS';

export const CELL_BOARD_SUMMARY_MANUFACTURING_LOCATION =
  'CELL_BOARD_SUMMARY_MANUFACTURING_LOCATION';
export const CELL_BOARD_SUMMARY_TABLE_IS_COMPACT =
  'CELL_BOARD_SUMMARY_TABLE_IS_COMPACT';
export const CELL_BOARD_SUMMARY_TABLE_COLUMN_WIDTHS =
  'CELL_BOARD_SUMMARY_TABLE_COLUMN_WIDTHS';
export const CELL_BOARD_SUMMARY_TABLE_HIDDEN_COLUMNS =
  'CELL_BOARD_SUMMARY_TABLE_HIDDEN_COLUMNS';
export const CELL_BOARD_SUMMARY_TABLE_PAGE_SIZE =
  'CELL_BOARD_SUMMARY_TABLE_PAGE_SIZE';
export const CELL_BOARD_SUMMARY_TABLE_SORTING =
  'CELL_BOARD_SUMMARY_TABLE_SORTING';
export const CELL_BOARD_SUMMARY_INCLUDE_EMPTY =
  'CELL_BOARD_SUMMARY_INCLUDE_EMPTY';
export const CELL_BOARD_SUMMARY_SHOW_VARIANCE =
  'CELL_BOARD_SUMMARY_SHOW_VARIANCE';

export const CELL_BOARD_QUALITY_DETAIL_ACCORDION_EXPANDED =
  'CELL_BOARD_QUALITY_DETAIL_ACCORDION_EXPANDED';

export const CELL_LOGIN_INVENTORY_CENTER_ID = 'CELL_LOGIN_INVENTORY_CENTER_ID';
export const CELL_LOGIN_M_CELL_ID = 'CELL_LOGIN_M_CELL_ID';
export const CELL_LOGIN_M_CELL_COLLECTION_ID =
  'CELL_LOGIN_M_CELL_COLLECTION_ID';
export const CELL_LOGIN_SHIFT_SELECTED = 'CELL_LOGIN_SHIFT_SELECTED';

export const COATINGS_ENABLED = 'COATINGS_ENABLED';
export const COATINGS_PROFILE_TABLE_COLUMN_WIDTHS =
  'COATINGS_PROFILE_TABLE_COLUMN_WIDTHS';
export const COATINGS_PROFILE_TABLE_HIDDEN_COLUMNS =
  'COATINGS_PROFILE_TABLE_HIDDEN_COLUMNS';
export const COATINGS_PROFILE_TABLE_PAGE_SIZE =
  'COATINGS_PROFILE_TABLE_PAGE_SIZE';
export const COATINGS_PROFILE_TABLE_SORTING = 'COATINGS_PROFILE_TABLE_SORTING';

export const CREWING_GUIDE_ENABLED = 'CREWING_GUIDE_ENABLED';
export const CREWING_GUIDE_TABLE_COLUMN_ORDER =
  'CREWING_GUIDE_TABLE_COLUMN_ORDER ';
export const CREWING_GUIDE_TABLE_COLUMN_WIDTHS =
  'CREWING_GUIDE_TABLE_COLUMN_WIDTHS';
export const CREWING_GUIDE_TABLE_HIDDEN_COLUMNS =
  'CREWING_GUIDE_TABLE_HIDDEN_COLUMNS';
export const CREWING_GUIDE_TABLE_PAGE_SIZE = 'CREWING_GUIDE_TABLE_PAGE_SIZE';
export const CREWING_GUIDE_TABLE_SORTING = 'CREWING_GUIDE_TABLE_SORTING';

export const CREWING_M_CELL_LOGIN_REPORT_TABLE_HIDDEN_COLUMNS =
  'CREWING_M_CELL_LOGIN_REPORT_TABLE_HIDDEN_COLUMNS';
export const CREWING_M_CELL_LOGIN_REPORT_TABLE_COLUMN_ORDER =
  'CREWING_M_CELL_LOGIN_REPORT_TABLE_COLUMN_ORDER';
export const CREWING_M_CELL_LOGIN_REPORT_TABLE_COLUMN_WIDTHS =
  'CREWING_M_CELL_LOGIN_REPORT_TABLE_COLUMN_WIDTHS';
export const CREWING_M_CELL_LOGIN_REPORT_TABLE_PAGE_SIZE =
  'CREWING_M_CELL_LOGIN_REPORT_TABLE_PAGE_SIZE';
export const CREWING_M_CELL_LOGIN_REPORT_TABLE_SORTING =
  'CREWING_M_CELL_LOGIN_REPORT_TABLE_SORTING';

export const DEMAND_MGMT_M_CELL_TABLE_COLUMN_ORDER =
  'DEMAND_MGMT_M_CELL_TABLE_COLUMN_ORDER';
export const DEMAND_MGMT_M_CELL_TABLE_COLUMN_WIDTHS =
  'DEMAND_MGMT_M_CELL_TABLE_COLUMN_WIDTHS';
export const DEMAND_MGMT_M_CELL_CONFIG_PAGE_SIZE =
  'DEMAND_MGMT_M_CELL_CONFIG_PAGE_SIZE';
export const DEMAND_MGMT_M_CELL_CONFIG_SORTING =
  'DEMAND_MGMT_M_CELL_CONFIG_SORTING';

export const DEPARTMENT_TABLE_COLUMN_ORDER = 'DEPARTMENT_TABLE_COLUMN_ORDER';
export const DEPARTMENT_TABLE_COLUMN_WIDTHS = 'DEPARTMENT_TABLE_COLUMN_WIDTHS';
export const DEPARTMENT_TABLE_HIDDEN_COLUMNS =
  'DEPARTMENT_TABLE_HIDDEN_COLUMNS';
export const DEPARTMENT_TABLE_PAGE_SIZE = 'DEPARTMENT_TABLE_PAGE_SIZE';
export const DEPARTMENT_TABLE_SORTING = 'DEPARTMENT_TABLE_SORTING';

export const DEV_M_CELL_LOGIN_INVENTORY_CENTER_ID =
  'DEV_M_CELL_LOGIN_INVENTORY_CENTER_ID';
export const DEV_M_CELL_SHIFT_SELECTED = 'DEV_M_CELL_SHIFT_SELECTED';

export const DOWNTIME_ACCORDION_EXPANDED = 'DOWNTIME_ACCORDION_EXPANDED';

export const DOWNTIME_ESCALATION_ENABLED = 'DOWNTIME_ESCALATION_ENABLED';

export const DOWNTIME_REASON_TABLE_COLUMN_ORDER =
  'DOWNTIME_REASON_TABLE_COLUMN_ORDER';
export const DOWNTIME_REASON_TABLE_COLUMN_WIDTHS =
  'DOWNTIME_REASON_TABLE_COLUMN_WIDTHS';
export const DOWNTIME_REASON_TABLE_HIDDEN_COLUMNS =
  'DOWNTIME_REASON_TABLE_HIDDEN_COLUMNS';
export const DOWNTIME_REASON_TABLE_SORTING = 'DOWNTIME_REASON_TABLE_SORTING';
export const DOWNTIME_REASON_TABLE_PAGE_SIZE =
  'DOWNTIME_REASON_TABLE_PAGE_SIZE';

export const DOWNTIME_REASON_EXTENDED_PROPERTY_TABLE_SORTING =
  'DOWNTIME_REASON_EXTENDED_PROPERTY_TABLE_SORTING';
export const DOWNTIME_REASON_EXTENDED_PROPERTY_TABLE_COLUMN_ORDER =
  'DOWNTIME_REASON_EXTENDED_PROPERTY_TABLE_COLUMN_ORDER';

export const DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_COLUMN_ORDER =
  'DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_COLUMN_ORDER';
export const DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_COLUMN_WIDTHS =
  'DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_COLUMN_WIDTHS';
export const DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_HIDDEN_COLUMNS =
  'DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_HIDDEN_COLUMNS';
export const DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_PAGE_SIZE =
  'DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_PAGE_SIZE';
export const DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_SORTING =
  'DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_SORTING';

export const DOWNTIME_REPORT_TABLE_HIDDEN_COLUMNS =
  'DOWNTIME_REPORT_TABLE_HIDDEN_COLUMNS';
export const DOWNTIME_REPORT_TABLE_PAGE_SIZE =
  'DOWNTIME_REPORT_TABLE_PAGE_SIZE';

export const DOWNTIME_REASON_SELECTED_OPTIONS = 'DOWNTIME_REASON';
export const DOWNTIME_TABLE_COLUMN_ORDER = 'DOWNTIME_TABLE_COLUMN_ORDER';
export const DOWNTIME_TABLE_COLUMN_WIDTHS = 'DOWNTIME_TABLE_COLUMN_WIDTHS';
export const DOWNTIME_TABLE_HIDDEN_COLUMNS = 'DOWNTIME_TABLE_HIDDEN_COLUMNS';
export const DOWNTIME_TABLE_PAGE_SIZE = 'DOWNTIME_TABLE_PAGE_SIZE';
export const DOWNTIME_TABLE_SORTING = 'DOWNTIME_TABLE_SORTING';

export const EMAIL_TABLE_COLUMN_ORDER = 'EMAIL_TABLE_COLUMN_ORDER';
export const EMAIL_TABLE_COLUMN_WIDTHS = 'EMAIL_TABLE_COLUMN_WIDTHS';
export const EMAIL_TABLE_HIDDEN_COLUMNS = 'EMAIL_TABLE_HIDDEN_COLUMNS';
export const EMAIL_TABLE_SORTING = 'EMAIL_TABLE_SORTING';

export const EMPLOYEES_IN_M_CELL_TABLE_COLUMN_ORDER =
  'EMPLOYEES_IN_M_CELL_TABLE_COLUMN_ORDER';
export const EMPLOYEES_IN_M_CELL_TABLE_COLUMN_WIDTHS =
  'EMPLOYEES_IN_M_CELL_TABLE_COLUMN_WIDTHS';
export const EMPLOYEES_IN_M_CELL_TABLE_HIDDEN_COLUMNS =
  'EMPLOYEES_IN_M_CELL_TABLE_HIDDEN_COLUMNS';
export const EMPLOYEES_IN_M_CELL_TABLE_PAGE_SIZE =
  'EMPLOYEES_IN_M_CELL_TABLE_PAGE_SIZE';
export const EMPLOYEES_IN_M_CELL_TABLE_SORTING =
  'EMPLOYEES_IN_M_CELL_TABLE_SORTING';

export const EMPLOYEE_JOB_FUNCTION_HISTORY_TABLE_COLUMN_WIDTHS =
  'EMPLOYEE_JOB_FUNCTION_HISTORY_TABLE_COLUMN_WIDTHS';

export const EMPLOYEE_JOB_STATION_HISTORY_TABLE_COLUMN_WIDTHS =
  'EMPLOYEE_JOB_STATION_HISTORY_TABLE_COLUMN_WIDTHS';

export const EMPLOYEE_JOB_FUNCTION_TAG_HISTORY_TABLE_COLUMN_WIDTHS =
  'EMPLOYEE_JOB_FUNCTION_TAG_HISTORY_TABLE_COLUMN_WIDTHS';

export const EMPLOYEE_M_CELL_HISTORY_TABLE_COLUMN_WIDTHS =
  'EMPLOYEE_M_CELL_HISTORY_TABLE_COLUMN_WIDTHS';

export const EMPLOYEES_IN_OTHER_M_CELLS_TABLE_COLUMN_ORDER =
  'EMPLOYEES_IN_OTHER_M_CELLS_TABLE_COLUMN_ORDER';
export const EMPLOYEES_IN_OTHER_M_CELLS_TABLE_COLUMN_WIDTHS =
  'EMPLOYEES_IN_OTHER_M_CELLS_TABLE_COLUMN_WIDTHS';
export const EMPLOYEES_IN_OTHER_M_CELLS_TABLE_HIDDEN_COLUMNS =
  'EMPLOYEES_IN_OTHER_M_CELLS_TABLE_HIDDEN_COLUMNS';
export const EMPLOYEES_IN_OTHER_M_CELLS_TABLE_PAGE_SIZE =
  'EMPLOYEES_IN_OTHER_M_CELLS_TABLE_PAGE_SIZE';
export const EMPLOYEES_IN_OTHER_M_CELLS_TABLE_SORTING =
  'EMPLOYEES_IN_OTHER_M_CELLS_TABLE_SORTING';

export const EMPLOYEE_REPORT_TABLE_COLUMN_ORDER =
  'EMPLOYEE_REPORT_TABLE_COLUMN_ORDER';
export const EMPLOYEE_REPORT_TABLE_COLUMN_WIDTHS =
  'EMPLOYEE_REPORT_TABLE_COLUMN_WIDTHS';
export const EMPLOYEE_REPORT_TABLE_HIDDEN_COLUMNS =
  'EMPLOYEE_REPORT_TABLE_HIDDEN_COLUMNS';
export const EMPLOYEE_REPORT_TABLE_PAGE_SIZE =
  'EMPLOYEE_REPORT_TABLE_PAGE_SIZE';
export const EMPLOYEE_REPORT_TABLE_SORTING = 'EMPLOYEE_REPORT_TABLE_SORTING';

export const FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_COLUMN_ORDER =
  'FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_COLUMN_ORDER';
export const FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_COLUMN_WIDTHS =
  'FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_COLUMN_WIDTHS';
export const FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_HIDDEN_COLUMNS =
  'FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_HIDDEN_COLUMNS';
export const FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_PAGE_SIZE =
  'FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_PAGE_SIZE';
export const FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_SORTING =
  'FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_SORTING';

export const FILTER_INVENTORY_CENTER_ID = 'FILTER_INVENTORY_CENTER_ID';

export const HOME_LINK_CONFIG_TABLE_COLUMN_ORDER =
  'HOME_LINK_CONFIG_TABLE_COLUMN_ORDER';
export const HOME_LINK_CONFIG_TABLE_COLUMN_WIDTHS =
  'HOME_LINK_CONFIG_TABLE_COLUMN_WIDTHS';
export const HOME_LINK_CONFIG_TABLE_SORTING = 'HOME_LINK_CONFIG_TABLE_SORTING';
export const HOME_LINK_CONFIG_TABLE_HIDDEN_COLUMNS =
  'HOME_LINK_CONFIG_TABLE_HIDDEN_COLUMNS';

export const HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_ORDER =
  'HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_ORDER';
export const HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_WIDTHS =
  'HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_WIDTHS';
export const HOME_LINK_CONFIG_GROUP_TABLE_SORTING =
  'HOME_LINK_CONFIG_GROUP_TABLE_SORTING';
export const HOME_LINK_CONFIG_GROUP_TABLE_HIDDEN_COLUMNS =
  'HOME_LINK_CONFIG_GROUP_TABLE_HIDDEN_COLUMNS';

export const HELP_LINK_TABLE_COLUMN_ORDER = 'HELP_LINK_TABLE_COLUMN_ORDER';
export const HELP_LINK_TABLE_COLUMN_WIDTHS = 'HELP_LINK_TABLE_COLUMN_WIDTHS';
export const HELP_LINK_TABLE_HIDDEN_COLUMNS = 'HELP_LINK_TABLE_HIDDEN_COLUMNS';
export const HELP_LINK_TABLE_SORTING = 'HELP_LINK_TABLE_SORTING';
export const HELP_LINK_TABLE_PAGE_SIZE = 'HELP_LINK_TABLE_PAGE_SIZE';

export const INTEREST_TABLE_PAGE_SIZE = 'INTEREST_TABLE_PAGE_SIZE';
export const INTEREST_TABLE_SORTING = 'INTEREST_TABLE_SORTING';

export const INVENTORY_CENTER_TABLE_COLUMN_ORDER =
  'INVENTORY_CENTER_TABLE_COLUMN_ORDER';
export const INVENTORY_CENTER_TABLE_COLUMN_WIDTHS =
  'INVENTORY_CENTER_TABLE_COLUMN_WIDTHS';
export const INVENTORY_CENTER_TABLE_SORTING = 'INVENTORY_CENTER_TABLE_SORTING';

export const JOB_STATION_ASSIGNMENT_ENABLED = 'JOB_STATION_ASSIGNMENT_ENABLED';

export const JOB_STATIONS_ENABLED = 'JOB_STATIONS_ENABLED';

export const JOB_STATION_SEARCH_REPORT_EXPERIENCE_THRESHOLD_HOURS =
  'JOB_STATION_SEARCH_REPORT_EXPERIENCE_THRESHOLD_HOURS';
export const JOB_STATION_SEARCH_REPORT_TABLE_COLUMN_ORDER =
  'JOB_STATION_SEARCH_REPORT_TABLE_COLUMN_ORDER';
export const JOB_STATION_SEARCH_REPORT_TABLE_COLUMN_WIDTHS =
  'JOB_STATION_SEARCH_REPORT_TABLE_COLUMN_WIDTHS';
export const JOB_STATION_SEARCH_REPORT_TABLE_HIDDEN_COLUMNS =
  'JOB_STATION_SEARCH_REPORT_TABLE_HIDDEN_COLUMNS';
export const JOB_STATION_SEARCH_REPORT_TABLE_PAGE_SIZE =
  'JOB_STATION_SEARCH_REPORT_TABLE_PAGE_SIZE';
export const JOB_STATION_SEARCH_REPORT_TABLE_SORTING =
  'JOB_STATION_SEARCH_REPORT_TABLE_SORTING';

export const KEY_M_CELL_TABLE_COLUMN_ORDER = 'KEY_M_CELL_TABLE_COLUMN_ORDER';
export const KEY_M_CELL_TABLE_COLUMN_WIDTHS = 'KEY_M_CELL_TABLE_COLUMN_WIDTHS';
export const KEY_M_CELL_TABLE_PAGE_SIZE = 'KEY_M_CELL_TABLE_PAGE_SIZE';
export const KEY_M_CELL_TABLE_SORTING = 'KEY_M_CELL_TABLE_SORTING';

export const LMS_ASSESSMENT_STATUS_TABLE_PAGE_SIZE =
  'LMS_ASSESSMENT_STATUS_TABLE_PAGE_SIZE';
export const LMS_ASSESSMENT_STATUS_TABLE_COLUMN_WIDTHS =
  'LMS_ASSESSMENT_STATUS_TABLE_COLUMN_WIDTHS';

export const LMS_CONFIG_TABLE_COLUMN_ORDER = 'LMS_CONFIG_TABLE_COLUMN_ORDER';
export const LMS_CONFIG_TABLE_COLUMN_WIDTHS = 'LMS_CONFIG_TABLE_COLUMN_WIDTHS';
export const LMS_CONFIG_TABLE_HIDDEN_COLUMNS =
  'LMS_CONFIG_TABLE_HIDDEN_COLUMNS';
export const LMS_CONFIG_TABLE_SORTING = 'LMS_CONFIG_TABLE_SORTING';
export const LMS_CONFIG_TABLE_PAGE_SIZE = 'LMS_CONFIG_TABLE_PAGE_SIZE';

export const LMS_ENROLLMENT_ENABLED = 'LMS_ENROLLMENT_ENABLED';

export const MACHINE_CONFIGURATION_TABLE_COLUMN_ORDER =
  'MACHINE_CONFIGURATION_TABLE_COLUMN_ORDER';
export const MACHINE_CONFIGURATION_TABLE_COLUMN_WIDTHS =
  'MACHINE_CONFIGURATION_TABLE_COLUMN_WIDTHS';
export const MACHINE_CONFIGURATION_TABLE_HIDDEN_COLUMNS =
  'MACHINE_CONFIGURATION_TABLE_HIDDEN_COLUMNS';
export const MACHINE_CONFIGURATION_TABLE_PAGE_SIZE =
  'MACHINE_CONFIGURATION_TABLE_PAGE_SIZE';
export const MACHINE_CONFIGURATION_TABLE_SORTING =
  'MACHINE_CONFIGURATION_TABLE_SORTING';

export const M_CELL_ALERT_INCLUDE_EMPTY = 'M_CELL_ALERT_INCLUDE_EMPTY';
export const M_CELL_ALERT_LEVEL = 'M_CELL_ALERT_LEVEL';
export const M_CELL_ALERT_SORT = 'M_CELL_ALERT_SORT';

export const M_CELL_CAPACITY_TABLE_COLUMN_ORDER =
  'M_CELL_CAPACITY_TABLE_COLUMN_ORDER';
export const M_CELL_CAPACITY_TABLE_COLUMN_WIDTHS =
  'M_CELL_CAPACITY_TABLE_COLUMN_WIDTHS';
export const M_CELL_CAPACITY_TABLE_HIDDEN_COLUMNS =
  'M_CELL_CAPACITY_TABLE_HIDDEN_COLUMNS';
export const M_CELL_CAPACITY_TABLE_PAGE_SIZE =
  'M_CELL_CAPACITY_TABLE_PAGE_SIZE';
export const M_CELL_CAPACITY_TABLE_SORTING = 'M_CELL_CAPACITY_TABLE_SORTING';

export const M_CELL_COLLECTION_GLOBAL_MODE = 'M_CELL_COLLECTION_GLOBAL_MODE';
export const M_CELL_COLLECTION_TABLE_COLUMN_ORDER =
  'M_CELL_COLLECTION_TABLE_COLUMN_ORDER';
export const M_CELL_COLLECTION_TABLE_COLUMN_WIDTHS =
  'M_CELL_COLLECTION_TABLE_COLUMN_WIDTHS';
export const M_CELL_COLLECTION_TABLE_HIDDEN_COLUMNS =
  'M_CELL_COLLECTION_TABLE_HIDDEN_COLUMNS';
export const M_CELL_COLLECTION_TABLE_PAGE_SIZE =
  'M_CELL_COLLECTION_TABLE_PAGE_SIZE';
export const M_CELL_COLLECTION_TABLE_SORTING =
  'M_CELL_COLLECTION_TABLE_SORTING';

export const M_CELL_CURRENT_CAPACITY_TABLE_COLUMN_ORDER =
  'M_CELL_CURRENT_CAPACITY_TABLE_COLUMN_ORDER';
export const M_CELL_CURRENT_CAPACITY_TABLE_COLUMN_WIDTHS =
  'M_CELL_CURRENT_CAPACITY_TABLE_COLUMN_WIDTHS';
export const M_CELL_CURRENT_CAPACITY_TABLE_HIDDEN_COLUMNS =
  'M_CELL_CURRENT_CAPACITY_TABLE_HIDDEN_COLUMNS';
export const M_CELL_CURRENT_CAPACITY_TABLE_PAGE_SIZE =
  'M_CELL_CURRENT_CAPACITY_TABLE_PAGE_SIZE';
export const M_CELL_CURRENT_CAPACITY_TABLE_SORTING =
  'M_CELL_CURRENT_CAPACITY_TABLE_SORTING';

export const M_CELL_EMPLOYEE_TABLE_COLUMN_ORDER =
  'M_CELL_EMPLOYEE_TABLE_COLUMN_ORDER';
export const M_CELL_EMPLOYEE_TABLE_COLUMN_WIDTHS =
  'M_CELL_EMPLOYEE_TABLE_COLUMN_WIDTHS';
export const M_CELL_EMPLOYEE_TABLE_HIDDEN_COLUMNS =
  'M_CELL_EMPLOYEE_TABLE_HIDDEN_COLUMNS';
export const M_CELL_EMPLOYEE_TABLE_PAGE_SIZE =
  'M_CELL_EMPLOYEE_TABLE_PAGE_SIZE';
export const M_CELL_EMPLOYEE_TABLE_SORTING = 'M_CELL_EMPLOYEE_TABLE_SORTING';

export const M_CELL_EMPLOYEE_JOB_FUNCTION_TABLE_PAGE_SIZE =
  'M_CELL_EMPLOYEE_JOB_FUNCTION_TABLE_PAGE_SIZE';
export const M_CELL_EMPLOYEE_JOB_FUNCTION_TABLE_SORTING =
  'M_CELL_EMPLOYEE_JOB_FUNCTION_TABLE_SORTING';

export const M_CELL_EMPLOYEE_JOB_FUNCTION_TAG_TABLE_PAGE_SIZE =
  'M_CELL_EMPLOYEE_JOB_FUNCTION_TAG_TABLE_PAGE_SIZE';
export const M_CELL_EMPLOYEE_JOB_FUNCTION_TAG_TABLE_SORTING =
  'M_CELL_EMPLOYEE_JOB_FUNCTION_TAG_TABLE_SORTING';

export const M_CELL_LOGIN_TABLE_PAGE_SIZE = 'M_CELL_LOGIN_TABLE_PAGE_SIZE';

export const M_CELL_SORT_SEQUENCE_TABLE_ORDER =
  'M_CELL_SORT_SEQUENCE_TABLE_ORDER';
export const M_CELL_SORT_SEQUENCE_TABLE_SORTING =
  'M_CELL_SORT_SEQUENCE_TABLE_SORTING';
export const M_CELL_SORT_SEQUENCE_TABLE_PAGE_SIZE =
  'M_CELL_SORT_SEQUENCE_TABLE_PAGE_SIZE';
export const M_CELL_SORT_SEQUENCE_TABLE_COLUMN_WIDTHS =
  'M_CELL_SORT_SEQUENCE_TABLE_COLUMN_WIDTHS';

export const M_CELL_STATUS_ENABLED = 'M_CELL_STATUS_ENABLED';
export const M_CELL_STATUS_TABLE_COLUMN_ORDER =
  'M_CELL_STATUS_TABLE_COLUMN_ORDER';
export const M_CELL_STATUS_TABLE_COLUMN_WIDTHS =
  'M_CELL_STATUS_TABLE_COLUMN_WIDTHS';
export const M_CELL_STATUS_TABLE_HIDDEN_COLUMNS =
  'M_CELL_STATUS_TABLE_HIDDEN_COLUMNS';
export const M_CELL_STATUS_TABLE_PAGE_SIZE = 'M_CELL_STATUS_TABLE_PAGE_SIZE';
export const M_CELL_STATUS_TABLE_SORTING = 'M_CELL_STATUS_TABLE_SORTING';

export const MESSAGE_LIST_ACCORDION_EXPANDED =
  'MESSAGE_LIST_ACCORDION_EXPANDED';

export const METRICS_TABLE_COLUMN_ORDERING = 'METRICS_TABLE_COLUMN_ORDERING';
export const METRICS_TABLE_COLUMN_WIDTHS = 'METRICS_TABLE_COLUMN_WIDTHS';
export const METRICS_TABLE_HIDDEN_COLUMNS = 'METRICS_TABLE_HIDDEN_COLUMNS';
export const METRICS_TABLE_PAGE_SIZE = 'METRICS_TABLE_PAGE_SIZE';
export const METRICS_TABLE_SORTING = 'METRICS_TABLE_SORTING';

export const NULL_SETTING = 'NULL_SETTING'; // for use in situations where we don't care about the setting

export const PERMISSION_TABLE_COLUMN_ORDER = 'PERMISSION_TABLE_COLUMN_ORDER';
export const PERMISSION_TABLE_COLUMN_WIDTH = 'PERMISSION_TABLE_COLUMN_WIDTH';
export const PERMISSION_TABLE_HIDDEN_COLUMNS =
  'PERMISSION_TABLE_HIDDEN_COLUMNS';
export const PERMISSION_TABLE_PAGE_SIZE = 'PERMISSION_TABLE_PAGE_SIZE';
export const PERMISSION_TABLE_SORTING = 'PERMISSION_TABLE_SORTING';

export const PRODUCTION_METRIC_INTERVAL_FACILITATOR_IS_AUTO_REFRESH =
  'PRODUCTION_METRIC_INTERVAL_FACILITATOR_IS_AUTO_REFRESH';
export const PRODUCTION_METRIC_INTERVAL_FACILITATOR_TABLE_COLUMN_WIDTHS =
  'PRODUCTION_METRIC_INTERVAL_FACILITATOR_TABLE_COLUMN_WIDTHS';

export const REPORT_ENABLED = 'REPORT_ENABLED';
export const RESOURCE_TRACKING_ENABLED = 'RESOURCE_TRACKING_ENABLED';

export const RESOURCE_TRACKING_JOB_STATION_TABLE_MANUFACTURING_LOCATION =
  'RESOURCE_TRACKING_JOB_STATION_TABLE_MANUFACTURING_LOCATION';

export const RESOURCE_TRACKING_JOB_STATION_TABLE_COLUMN_ORDER =
  'RESOURCE_TRACKING_JOB_STATION_TABLE_COLUMN_ORDER';
export const RESOURCE_TRACKING_JOB_STATION_TABLE_COLUMN_WIDTHS =
  'RESOURCE_TRACKING_JOB_STATION_TABLE_COLUMN_WIDTHS';
export const RESOURCE_TRACKING_JOB_STATION_TABLE_HIDDEN_COLUMNS =
  'RESOURCE_TRACKING_JOB_STATION_TABLE_HIDDEN_COLUMNS';
export const RESOURCE_TRACKING_JOB_STATION_TABLE_PAGE_SIZE =
  'RESOURCE_TRACKING_JOB_STATION_TABLE_PAGE_SIZE';
export const RESOURCE_TRACKING_JOB_STATION_TABLE_SORTING =
  'RESOURCE_TRACKING_JOB_STATION_TABLE_SORTING';

export const RESOURCE_TRACKING_JOB_STATION_DE_TABLE_COLUMN_WIDTH =
  'RESOURCE_TRACKING_JOB_STATION_DE_TABLE_COLUMN_WIDTH';
export const RESOURCE_TRACKING_JOB_STATION_DE_TABLE_SORTING =
  'RESOURCE_TRACKING_JOB_STATION_DE_TABLE_SORTING';

export const RESOURCE_TRACKING_JOB_STATION_PPE_TABLE_COLUMN_WIDTH =
  'RESOURCE_TRACKING_JOB_STATION_PPE_TABLE_COLUMN_WIDTH';
export const RESOURCE_TRACKING_JOB_STATION_PPE_TABLE_SORTING =
  'RESOURCE_TRACKING_JOB_STATION_PPE_TABLE_SORTING';

export const SCORECARD_ENABLED = 'SCORECARD_ENABLED';

export const SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_COLUMN_ORDER =
  'SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_COLUMN_ORDER';
export const SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_COLUMN_WIDTHS =
  'SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_COLUMN_WIDTHS';
export const SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_HIDDEN_COLUMNS =
  'SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_HIDDEN_COLUMNS';
export const SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_PAGE_SIZE =
  'SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_PAGE_SIZE';
export const SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_SORTING =
  'SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_SORTING';
export const SUPPORT_HIERARCHY_MCELL_TABLE_COLUMN_ORDER =
  'SUPPORT_HIERARCHY_MCELL_TABLE_COLUMN_ORDER';
export const SUPPORT_HIERARCHY_MCELL_TABLE_COLUMN_WIDTHS =
  'SUPPORT_HIERARCHY_MCELL_TABLE_COLUMN_WIDTHS';
export const SUPPORT_HIERARCHY_MCELL_TABLE_HIDDEN_COLUMNS =
  'SUPPORT_HIERARCHY_MCELL_TABLE_HIDDEN_COLUMNS';
export const SUPPORT_HIERARCHY_MCELL_TABLE_SORTING =
  'SUPPORT_HIERARCHY_MCELL_TABLE_SORTING';

export const SUPPORT_GROUP_TABLE_COLUMN_ORDER =
  'SUPPORT_GROUP_TABLE_COLUMN_ORDER';
export const SUPPORT_GROUP_TABLE_COLUMN_WIDTHS =
  'SUPPORT_GROUP_TABLE_COLUMN_WIDTHS';
export const SUPPORT_GROUP_TABLE_HIDDEN_COLUMNS =
  'SUPPORT_GROUP_TABLE_HIDDEN_COLUMNS';
export const SUPPORT_GROUP_TABLE_PAGE_SIZE = 'SUPPORT_GROUP_TABLE_PAGE_SIZE';
export const SUPPORT_GROUP_TABLE_SORTING = 'SUPPORT_GROUP_TABLE_SORTING';

export const SUPPORT_GROUP_MEMBERS_TABLE_COLUMN_ORDER =
  'SUPPORT_GROUP_MEMBERS_TABLE_COLUMN_ORDER';
export const SUPPORT_GROUP_MEMBERS_TABLE_COLUMN_WIDTHS =
  'SUPPORT_GROUP_MEMBERS_TABLE_COLUMN_WIDTHS';
export const SUPPORT_GROUP_MEMBERS_TABLE_HIDDEN_COLUMNS =
  'SUPPORT_GROUP_MEMBERS_TABLE_HIDDEN_COLUMNS';
export const SUPPORT_GROUP_MEMBERS_TABLE_PAGE_SIZE =
  'SUPPORT_GROUP_MEMBERS_TABLE_PAGE_SIZE';
export const SUPPORT_GROUP_MEMBERS_TABLE_SORTING =
  'SUPPORT_GROUP_MEMBERS_TABLE_SORTING';

export const SUPPORT_HIERARCHY_ENABLED = 'SUPPORT_HIERARCHY_ENABLED';
export const SUPPORT_HIERARCHY_SUPPORT_GROUP_SELECTED_ID =
  'SUPPORT_HIERARCHY_SUPPORT_GROUP_SELECTED_ID';

export const SUPPORT_HIERARCHY_TABLE_COLUMN_ORDER =
  'SUPPORT_HIERARCHY_TABLE_COLUMN_ORDER';
export const SUPPORT_HIERARCHY_TABLE_COLUMN_WIDTHS =
  'SUPPORT_HIERARCHY_TABLE_COLUMN_WIDTHS';
export const SUPPORT_HIERARCHY_TABLE_HIDDEN_COLUMNS =
  'SUPPORT_HIERARCHY_TABLE_HIDDEN_COLUMNS';
export const SUPPORT_HIERARCHY_TABLE_PAGE_SIZE =
  'SUPPORT_HIERARCHY_TABLE_PAGE_SIZE';
export const SUPPORT_HIERARCHY_TABLE_SORTING =
  'SUPPORT_HIERARCHY_TABLE_SORTING';
export const SUPPORT_HIERARCHY_MCELL_TABLE_FILTER_SELECTIONS =
  'SUPPORT_HIERARCHY_MCELL_TABLE_FILTER_SELECTIONS';

export const SCHEDULING_TOOL_CAPACITY_UTILIZATION_DESCRIPTION_CHECKED =
  'SCHEDULING_TOOL_CAPACITY_UTILIZATION_DESCRIPTION_CHECKED';
export const SCHEDULING_TOOL_CAPACITY_UTILIZATION_SELECTED_GROUPS =
  'SCHEDULING_TOOL_CAPACITY_UTILIZATION_SELECTED_GROUPS';
export const SCHEDULING_TOOL_CAPACITY_UTILIZATION_SELECTED_PRODUCING_LOCATIONS =
  'SCHEDULING_TOOL_CAPACITY_UTILIZATION_SELECTED_PRODUCING_LOCATIONS';
export const SCHEDULING_TOOL_ENABLED = 'SCHEDULING_TOOL_ENABLED';
export const SCHEDULING_TOOL_PRODUCING_LOCATION_METRIC_SELECTED =
  'SCHEDULING_TOOL_PRODUCING_LOCATION_METRIC_SELECTED';

export const SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_COLUMN_WIDTHS';
export const SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_PAGE_SIZE =
  'SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_PAGE_SIZE';
export const SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_SORTING =
  'SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_SORTING';

export const SCHEDULING_TOOL_M_CELL_MAP_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_M_CELL_MAP_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_M_CELL_MAP_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_M_CELL_MAP_TABLE_COLUMN_WIDTHS';
export const SCHEDULING_TOOL_M_CELL_MAP_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_M_CELL_MAP_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_M_CELL_MAP_TABLE_PAGE_SIZE =
  'SCHEDULING_TOOL_M_CELL_MAP_TABLE_PAGE_SIZE';
export const SCHEDULING_TOOL_M_CELL_MAP_TABLE_SORTING =
  'SCHEDULING_TOOL_M_CELL_MAP_TABLE_SORTING';

export const SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_COLUMN_WIDTHS';
export const SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_PAGE_SIZE =
  'SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_PAGE_SIZE';
export const SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_SORTING =
  'SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_SORTING';

export const SCHEDULING_TOOL_SALES_ORDER_TYPE_FILTER_SELECTIONS =
  'SCHEDULING_TOOL_SALES_ORDER_TYPE_FILTER_SELECTIONS';
export const SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TYPE_FILTER_SELECTIONS =
  'SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TYPE_FILTER_SELECTIONS';
export const SCHEDULING_TOOL_HARD_HOLD_TYPE_FILTER_SELECTIONS =
  'SCHEDULING_TOOL_HARD_HOLD_TYPE_FILTER_SELECTIONS';

export const SCHEDULING_TOOL_SALES_ORDER_TYPE_SELECTED =
  'SCHEDULING_TOOL_SALES_ORDER_TYPE_SELECTED';
export const SCHEDULING_TOOL_SALES_ORDER_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_SALES_ORDER_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_SALES_ORDER_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_SALES_ORDER_TABLE_COLUMN_WIDTHS';
export const SCHEDULING_TOOL_SALES_ORDER_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_SALES_ORDER_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_SALES_ORDER_TABLE_SORTING =
  'SCHEDULING_TOOL_SALES_ORDER_TABLE_SORTING';

export const SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_SORTING =
  'SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_SORTING';
export const SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_COLUMN_WIDTHS';

export const SCHEDULING_TOOL_HARD_HOLD_TABLE_SORTING =
  'SCHEDULING_TOOL_HARD_HOLD_TABLE_SORTING';
export const SCHEDULING_TOOL_HARD_HOLD_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_HARD_HOLD_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_HARD_HOLD_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_HARD_HOLD_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_HARD_HOLD_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_HARD_HOLD_TABLE_COLUMN_WIDTHS';

export const SCHEDULING_TOOL_CHANGE_LOG_ACCORDION_EXPANDED =
  'SCHEDULING_TOOL_CHANGE_LOG_ACCORDION_EXPANDED';
export const SCHEDULING_TOOL_CHANGE_LOG_GLOBAL_MODE =
  'SCHEDULING_TOOL_CHANGE_LOG_GLOBAL_MODE';

export const SCHEDULING_TOOL_CHANGE_LOG_TABLE_COLUMN_ORDER =
  'SCHEDULING_TOOL_CHANGE_LOG_TABLE_COLUMN_ORDER';
export const SCHEDULING_TOOL_CHANGE_LOG_TABLE_COLUMN_WIDTHS =
  'SCHEDULING_TOOL_CHANGE_LOG_TABLE_COLUMN_WIDTHS';
export const SCHEDULING_TOOL_CHANGE_LOG_TABLE_HIDDEN_COLUMNS =
  'SCHEDULING_TOOL_CHANGE_LOG_TABLE_HIDDEN_COLUMNS';
export const SCHEDULING_TOOL_CHANGE_LOG_TABLE_PAGE_SIZE =
  'SCHEDULING_TOOL_CHANGE_LOG_TABLE_PAGE_SIZE';
export const SCHEDULING_TOOL_CHANGE_LOG_TABLE_TABLE_SORTING =
  'SCHEDULING_TOOL_CHANGE_LOG_TABLE_TABLE_SORTING';

export const SCHEDULING_TOOL_SHIFT_SELECTED = 'SCHEDULING_TOOL_SHIFT_SELECTED';

export const SCHEDULING_TOOL_EMPLOYEE_UTILIZATION_DATA_SORTING =
  'SCHEDULING_TOOL_EMPLOYEE_UTILIZATION_DATA_SORTING';

export const SCHEDULING_TOOL_ORDER_SEARCH_HIDE_PROCESSED_ORDERS =
  'SCHEDULING_TOOL_ORDER_SEARCH_HIDE_PROCESSED_ORDERS';

export const LSW_SHEET_TABLE_COLUMN_ORDER = 'LSW_SHEET_TABLE_COLUMN_ORDER';
export const LSW_SHEET_TABLE_COLUMN_WIDTHS = 'LSW_SHEET_TABLE_COLUMN_WIDTHS';
export const LSW_SHEET_TABLE_HIDDEN_COLUMNS = 'LSW_SHEET_TABLE_HIDDEN_COLUMNS';
export const LSW_SHEET_TABLE_SORTING = 'LSW_SHEET_TABLE_SORTING';
export const LSW_SHEET_TABLE_PAGE_SIZE = 'LSW_SHEET_TABLE_PAGE_SIZE';

export const LSW_TASK_TABLE_COLUMN_ORDER = 'LSW_TASK_TABLE_COLUMN_ORDER';
export const LSW_TASK_TABLE_COLUMN_WIDTHS = 'LSW_TASK_TABLE_COLUMN_WIDTHS';
export const LSW_TASK_TABLE_HIDDEN_COLUMNS = 'LSW_TASK_TABLE_HIDDEN_COLUMNS';
export const LSW_TASK_TABLE_SORTING = 'LSW_TASK_TABLE_SORTING';
export const LSW_TASK_TABLE_PAGE_SIZE = 'LSW_TASK_TABLE_PAGE_SIZE';

export const LSW_TASK_GROUP_TABLE_COLUMN_ORDER =
  'LSW_TASK_GROUP_TABLE_COLUMN_ORDER';
export const LSW_TASK_GROUP_TABLE_COLUMN_WIDTHS =
  'LSW_TASK_GROUP_TABLE_COLUMN_WIDTHS';
export const LSW_TASK_GROUP_TABLE_HIDDEN_COLUMNS =
  'LSW_TASK_GROUP_TABLE_HIDDEN_COLUMNS';
export const LSW_TASK_GROUP_TABLE_SORTING = 'LSW_TASK_GROUP_TABLE_SORTING';
export const LSW_TASK_GROUP_TABLE_PAGE_SIZE = 'LSW_TASK_GROUP_TABLE_PAGE_SIZE';

export const LSW_REPORT_TABLE_COLUMN_ORDER = 'LSW_REPORT_TABLE_COLUMN_ORDER';
export const LSW_REPORT_TABLE_COLUMN_WIDTHS = 'LSW_REPORT_TABLE_COLUMN_WIDTHS';
export const LSW_REPORT_TABLE_HIDDEN_COLUMNS =
  'LSW_REPORT_TABLE_HIDDEN_COLUMNS';
export const LSW_REPORT_TABLE_SORTING = 'LSW_REPORT_TABLE_SORTING';
export const LSW_REPORT_TABLE_PAGE_SIZE = 'LSW_REPORT_TABLE_PAGE_SIZE';

export const LSW_ENABLED = 'LSW_ENABLED';

export const BANNER_INFO = 'BANNER_INFO';
export const LARGE_SCREEN_NAV_COLLAPSED = 'LARGE_SCREEN_NAV_COLLAPSED';

export type UserSettingKey =
  | typeof LARGE_SCREEN_NAV_COLLAPSED
  | typeof BANNER_INFO
  | typeof BANNER_LEVEL
  | typeof BANNER_MESSAGE
  | typeof BANNER_LOCATION
  | typeof CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_DELIVERY_DETAIL_METRIC_TABLE_SORTING
  | typeof CELL_BOARD_DANGEROUS_EQUIPMENT_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_DANGEROUS_EQUIPMENT_TABLE_SORTING
  | typeof CELL_BOARD_ENABLED
  | typeof CELL_BOARD_HOUR_BY_HOUR_BAR_CHART_ACCORDION_EXPANDED
  | typeof CELL_BOARD_HOUR_BY_HOUR_SHIFT_SELECTED
  | typeof CELL_BOARD_MANUFACTURING_LOCATION
  | typeof CELL_BOARD_SAFETY_MESSAGE_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_SAFETY_MESSAGE_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_SAFETY_MESSAGE_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_SAFETY_MESSAGE_TABLE_SORTING
  | typeof CELL_BOARD_SAFETY_MESSAGE_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_PRODUCTION_METRIC_INTERVAL_TABLE_SORTING
  | typeof CELL_BOARD_INVENTORY_CENTER_ID
  | typeof CELL_BOARD_REPORT_MANUFACTURING_LOCATION
  | typeof CELL_BOARD_REPORT_SHIFTS
  | typeof CELL_BOARD_M_CELL_COLUMN_ORDER
  | typeof CELL_BOARD_M_CELL_COLUMN_WIDTHS
  | typeof CELL_BOARD_M_CELL_CONFIG_PAGE_SIZE
  | typeof CELL_BOARD_M_CELL_CONFIG_SORTING
  | typeof CELL_BOARD_M_CELL_ID
  | typeof CELL_BOARD_M_CELL_COLLECTION_ID
  | typeof CELL_BOARD_M_CELL_TYPE_SELECTED
  | typeof CELL_BOARD_MESSAGE_GLOBAL_MODE
  | typeof CELL_BOARD_MESSAGE_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_MESSAGE_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_MESSAGE_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_MESSAGE_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_MESSAGE_TABLE_SORTING
  | typeof CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_METRIC_HISTORY_REPORT_TABLE_SORTING
  | typeof CELL_BOARD_M_CELL_TASK_REPORT_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_M_CELL_TASK_REPORT_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_M_CELL_TASK_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_M_CELL_TASK_REPORT_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_M_CELL_TASK_REPORT_TABLE_SORTING
  | typeof CELL_BOARD_PERSONAL_PROTECTIVE_EQUIPMENT_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_PRODUCTION_DETAIL_ACCORDION_EXPANDED
  | typeof CELL_BOARD_PERSONAL_PROTECTIVE_EQUIPMENT_TABLE_SORTING
  | typeof CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_SCANNED_OFFLINE_PART_TABLE_SORTING
  | typeof CELL_BOARD_ROSTER_TABLE_COLUMN_ORDER
  | typeof CELL_BOARD_ROSTER_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_ROSTER_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_ROSTER_TABLE_SORTING
  | typeof CELL_BOARD_SHIFT_SELECTED
  | typeof CELL_BOARD_SHIFTS_SELECTED
  | typeof CELL_BOARD_SHOW_EMPTY
  | typeof CELL_BOARD_SUMMARY_MANUFACTURING_LOCATION
  | typeof CELL_BOARD_SUMMARY_TABLE_IS_COMPACT
  | typeof CELL_BOARD_SUMMARY_TABLE_COLUMN_WIDTHS
  | typeof CELL_BOARD_SUMMARY_TABLE_HIDDEN_COLUMNS
  | typeof CELL_BOARD_SUMMARY_TABLE_PAGE_SIZE
  | typeof CELL_BOARD_SUMMARY_TABLE_SORTING
  | typeof CELL_BOARD_SUMMARY_INCLUDE_EMPTY
  | typeof CELL_BOARD_SUMMARY_SHOW_VARIANCE
  | typeof CELL_BOARD_SLIDESHOW_INTERVAL_ACTIVE
  | typeof CELL_BOARD_SCORECARD_SLIDESHOW_ACTIVE
  | typeof CELL_BOARD_QUALITY_DETAIL_ACCORDION_EXPANDED
  | typeof CELL_LOGIN_INVENTORY_CENTER_ID
  | typeof CELL_LOGIN_M_CELL_ID
  | typeof CELL_LOGIN_M_CELL_COLLECTION_ID
  | typeof CELL_LOGIN_SHIFT_SELECTED
  | typeof COATINGS_ENABLED
  | typeof COATINGS_PROFILE_TABLE_COLUMN_WIDTHS
  | typeof COATINGS_PROFILE_TABLE_HIDDEN_COLUMNS
  | typeof COATINGS_PROFILE_TABLE_PAGE_SIZE
  | typeof COATINGS_PROFILE_TABLE_SORTING
  | typeof CREWING_GUIDE_ENABLED
  | typeof CREWING_GUIDE_TABLE_COLUMN_ORDER
  | typeof CREWING_GUIDE_TABLE_COLUMN_WIDTHS
  | typeof CREWING_GUIDE_TABLE_HIDDEN_COLUMNS
  | typeof CREWING_GUIDE_TABLE_PAGE_SIZE
  | typeof CREWING_GUIDE_TABLE_SORTING
  | typeof CREWING_M_CELL_LOGIN_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof CREWING_M_CELL_LOGIN_REPORT_TABLE_COLUMN_ORDER
  | typeof CREWING_M_CELL_LOGIN_REPORT_TABLE_COLUMN_WIDTHS
  | typeof CREWING_M_CELL_LOGIN_REPORT_TABLE_PAGE_SIZE
  | typeof CREWING_M_CELL_LOGIN_REPORT_TABLE_SORTING
  | typeof DEMAND_MGMT_M_CELL_CONFIG_PAGE_SIZE
  | typeof DEMAND_MGMT_M_CELL_CONFIG_SORTING
  | typeof DEMAND_MGMT_M_CELL_TABLE_COLUMN_ORDER
  | typeof DEMAND_MGMT_M_CELL_TABLE_COLUMN_WIDTHS
  | typeof DEPARTMENT_TABLE_COLUMN_ORDER
  | typeof DEPARTMENT_TABLE_COLUMN_WIDTHS
  | typeof DEPARTMENT_TABLE_HIDDEN_COLUMNS
  | typeof DEPARTMENT_TABLE_PAGE_SIZE
  | typeof DEPARTMENT_TABLE_SORTING
  | typeof DEV_M_CELL_LOGIN_INVENTORY_CENTER_ID
  | typeof DEV_M_CELL_SHIFT_SELECTED
  | typeof DOWNTIME_ACCORDION_EXPANDED
  | typeof DOWNTIME_ESCALATION_ENABLED
  | typeof DOWNTIME_REASON_SELECTED_OPTIONS
  | typeof DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_COLUMN_ORDER
  | typeof DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_COLUMN_WIDTHS
  | typeof DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_HIDDEN_COLUMNS
  | typeof DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_PAGE_SIZE
  | typeof DOWNTIME_REASON_SUPPORT_GROUP_MAPPING_TABLE_SORTING
  | typeof DOWNTIME_REASON_TABLE_COLUMN_ORDER
  | typeof DOWNTIME_REASON_TABLE_COLUMN_WIDTHS
  | typeof DOWNTIME_REASON_TABLE_HIDDEN_COLUMNS
  | typeof DOWNTIME_REASON_TABLE_PAGE_SIZE
  | typeof DOWNTIME_REASON_TABLE_SORTING
  | typeof DOWNTIME_REASON_EXTENDED_PROPERTY_TABLE_SORTING
  | typeof DOWNTIME_REASON_EXTENDED_PROPERTY_TABLE_COLUMN_ORDER
  | typeof DOWNTIME_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof DOWNTIME_REPORT_TABLE_PAGE_SIZE
  | typeof DOWNTIME_TABLE_COLUMN_ORDER
  | typeof DOWNTIME_TABLE_COLUMN_WIDTHS
  | typeof DOWNTIME_TABLE_HIDDEN_COLUMNS
  | typeof DOWNTIME_TABLE_PAGE_SIZE
  | typeof DOWNTIME_TABLE_SORTING
  | typeof EMAIL_TABLE_COLUMN_ORDER
  | typeof EMAIL_TABLE_COLUMN_WIDTHS
  | typeof EMAIL_TABLE_HIDDEN_COLUMNS
  | typeof EMAIL_TABLE_SORTING
  | typeof EMPLOYEES_IN_M_CELL_TABLE_COLUMN_ORDER
  | typeof EMPLOYEES_IN_M_CELL_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEES_IN_M_CELL_TABLE_HIDDEN_COLUMNS
  | typeof EMPLOYEES_IN_M_CELL_TABLE_PAGE_SIZE
  | typeof EMPLOYEES_IN_M_CELL_TABLE_SORTING
  | typeof EMPLOYEES_IN_OTHER_M_CELLS_TABLE_COLUMN_ORDER
  | typeof EMPLOYEES_IN_OTHER_M_CELLS_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEES_IN_OTHER_M_CELLS_TABLE_HIDDEN_COLUMNS
  | typeof EMPLOYEES_IN_OTHER_M_CELLS_TABLE_PAGE_SIZE
  | typeof EMPLOYEES_IN_OTHER_M_CELLS_TABLE_SORTING
  | typeof EMPLOYEE_JOB_FUNCTION_HISTORY_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEE_JOB_STATION_HISTORY_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEE_JOB_FUNCTION_TAG_HISTORY_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEE_M_CELL_HISTORY_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEE_REPORT_TABLE_COLUMN_ORDER
  | typeof EMPLOYEE_REPORT_TABLE_COLUMN_WIDTHS
  | typeof EMPLOYEE_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof EMPLOYEE_REPORT_TABLE_PAGE_SIZE
  | typeof EMPLOYEE_REPORT_TABLE_SORTING
  | typeof FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_COLUMN_ORDER
  | typeof FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_COLUMN_WIDTHS
  | typeof FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_HIDDEN_COLUMNS
  | typeof FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_PAGE_SIZE
  | typeof FACTORY_AUTOMATION_OVERHEAD_DOOR_SIGNAL_MODE_TABLE_SORTING
  | typeof FILTER_INVENTORY_CENTER_ID
  | typeof INTEREST_TABLE_PAGE_SIZE
  | typeof INVENTORY_CENTER_TABLE_COLUMN_ORDER
  | typeof INVENTORY_CENTER_TABLE_COLUMN_WIDTHS
  | typeof INVENTORY_CENTER_TABLE_SORTING
  | typeof HELP_LINK_TABLE_COLUMN_ORDER
  | typeof HELP_LINK_TABLE_COLUMN_WIDTHS
  | typeof HELP_LINK_TABLE_HIDDEN_COLUMNS
  | typeof HELP_LINK_TABLE_SORTING
  | typeof HELP_LINK_TABLE_PAGE_SIZE
  | typeof HOME_LINK_CONFIG_TABLE_COLUMN_ORDER
  | typeof HOME_LINK_CONFIG_TABLE_COLUMN_WIDTHS
  | typeof HOME_LINK_CONFIG_TABLE_SORTING
  | typeof HOME_LINK_CONFIG_TABLE_HIDDEN_COLUMNS
  | typeof HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_ORDER
  | typeof HOME_LINK_CONFIG_GROUP_TABLE_COLUMN_WIDTHS
  | typeof HOME_LINK_CONFIG_GROUP_TABLE_SORTING
  | typeof HOME_LINK_CONFIG_GROUP_TABLE_HIDDEN_COLUMNS
  | typeof INTEREST_TABLE_SORTING
  | typeof JOB_STATION_ASSIGNMENT_ENABLED
  | typeof JOB_STATIONS_ENABLED
  | typeof JOB_STATION_SEARCH_REPORT_EXPERIENCE_THRESHOLD_HOURS
  | typeof JOB_STATION_SEARCH_REPORT_TABLE_COLUMN_ORDER
  | typeof JOB_STATION_SEARCH_REPORT_TABLE_COLUMN_WIDTHS
  | typeof JOB_STATION_SEARCH_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof JOB_STATION_SEARCH_REPORT_TABLE_PAGE_SIZE
  | typeof JOB_STATION_SEARCH_REPORT_TABLE_SORTING
  | typeof KEY_M_CELL_TABLE_COLUMN_ORDER
  | typeof KEY_M_CELL_TABLE_COLUMN_WIDTHS
  | typeof KEY_M_CELL_TABLE_PAGE_SIZE
  | typeof KEY_M_CELL_TABLE_SORTING
  | typeof LMS_ASSESSMENT_STATUS_TABLE_PAGE_SIZE
  | typeof LMS_ASSESSMENT_STATUS_TABLE_COLUMN_WIDTHS
  | typeof LMS_CONFIG_TABLE_COLUMN_ORDER
  | typeof LMS_CONFIG_TABLE_COLUMN_WIDTHS
  | typeof LMS_CONFIG_TABLE_HIDDEN_COLUMNS
  | typeof LMS_CONFIG_TABLE_SORTING
  | typeof LMS_CONFIG_TABLE_PAGE_SIZE
  | typeof LMS_ENROLLMENT_ENABLED
  | typeof MACHINE_CONFIGURATION_TABLE_COLUMN_ORDER
  | typeof MACHINE_CONFIGURATION_TABLE_COLUMN_WIDTHS
  | typeof MACHINE_CONFIGURATION_TABLE_HIDDEN_COLUMNS
  | typeof MACHINE_CONFIGURATION_TABLE_PAGE_SIZE
  | typeof MACHINE_CONFIGURATION_TABLE_SORTING
  | typeof M_CELL_ALERT_INCLUDE_EMPTY
  | typeof M_CELL_ALERT_LEVEL
  | typeof M_CELL_ALERT_SORT
  | typeof M_CELL_CAPACITY_TABLE_COLUMN_ORDER
  | typeof M_CELL_CAPACITY_TABLE_COLUMN_WIDTHS
  | typeof M_CELL_CAPACITY_TABLE_HIDDEN_COLUMNS
  | typeof M_CELL_CAPACITY_TABLE_PAGE_SIZE
  | typeof M_CELL_CAPACITY_TABLE_SORTING
  | typeof M_CELL_COLLECTION_GLOBAL_MODE
  | typeof M_CELL_COLLECTION_TABLE_COLUMN_ORDER
  | typeof M_CELL_COLLECTION_TABLE_COLUMN_WIDTHS
  | typeof M_CELL_COLLECTION_TABLE_HIDDEN_COLUMNS
  | typeof M_CELL_COLLECTION_TABLE_PAGE_SIZE
  | typeof M_CELL_COLLECTION_TABLE_SORTING
  | typeof M_CELL_CURRENT_CAPACITY_TABLE_COLUMN_ORDER
  | typeof M_CELL_CURRENT_CAPACITY_TABLE_COLUMN_WIDTHS
  | typeof M_CELL_CURRENT_CAPACITY_TABLE_HIDDEN_COLUMNS
  | typeof M_CELL_CURRENT_CAPACITY_TABLE_PAGE_SIZE
  | typeof M_CELL_CURRENT_CAPACITY_TABLE_SORTING
  | typeof M_CELL_EMPLOYEE_TABLE_COLUMN_ORDER
  | typeof M_CELL_EMPLOYEE_TABLE_COLUMN_WIDTHS
  | typeof M_CELL_EMPLOYEE_TABLE_HIDDEN_COLUMNS
  | typeof M_CELL_EMPLOYEE_TABLE_PAGE_SIZE
  | typeof M_CELL_EMPLOYEE_TABLE_SORTING
  | typeof M_CELL_EMPLOYEE_JOB_FUNCTION_TABLE_PAGE_SIZE
  | typeof M_CELL_EMPLOYEE_JOB_FUNCTION_TABLE_SORTING
  | typeof M_CELL_EMPLOYEE_JOB_FUNCTION_TAG_TABLE_PAGE_SIZE
  | typeof M_CELL_EMPLOYEE_JOB_FUNCTION_TAG_TABLE_SORTING
  | typeof M_CELL_LOGIN_TABLE_PAGE_SIZE
  | typeof M_CELL_SORT_SEQUENCE_TABLE_ORDER
  | typeof M_CELL_SORT_SEQUENCE_TABLE_SORTING
  | typeof M_CELL_SORT_SEQUENCE_TABLE_PAGE_SIZE
  | typeof M_CELL_SORT_SEQUENCE_TABLE_COLUMN_WIDTHS
  | typeof M_CELL_STATUS_ENABLED
  | typeof M_CELL_STATUS_TABLE_COLUMN_ORDER
  | typeof M_CELL_STATUS_TABLE_COLUMN_WIDTHS
  | typeof M_CELL_STATUS_TABLE_HIDDEN_COLUMNS
  | typeof M_CELL_STATUS_TABLE_PAGE_SIZE
  | typeof M_CELL_STATUS_TABLE_SORTING
  | typeof MESSAGE_LIST_ACCORDION_EXPANDED
  | typeof METRICS_TABLE_COLUMN_ORDERING
  | typeof METRICS_TABLE_COLUMN_WIDTHS
  | typeof METRICS_TABLE_HIDDEN_COLUMNS
  | typeof METRICS_TABLE_PAGE_SIZE
  | typeof METRICS_TABLE_SORTING
  | typeof NULL_SETTING
  | typeof PERMISSION_TABLE_COLUMN_ORDER
  | typeof PERMISSION_TABLE_COLUMN_WIDTH
  | typeof PERMISSION_TABLE_HIDDEN_COLUMNS
  | typeof PERMISSION_TABLE_PAGE_SIZE
  | typeof PERMISSION_TABLE_SORTING
  | typeof PRODUCTION_METRIC_INTERVAL_FACILITATOR_IS_AUTO_REFRESH
  | typeof PRODUCTION_METRIC_INTERVAL_FACILITATOR_TABLE_COLUMN_WIDTHS
  | typeof REPORT_ENABLED
  | typeof RESOURCE_TRACKING_ENABLED
  | typeof RESOURCE_TRACKING_JOB_STATION_TABLE_MANUFACTURING_LOCATION
  | typeof RESOURCE_TRACKING_JOB_STATION_TABLE_COLUMN_ORDER
  | typeof RESOURCE_TRACKING_JOB_STATION_TABLE_COLUMN_WIDTHS
  | typeof RESOURCE_TRACKING_JOB_STATION_TABLE_HIDDEN_COLUMNS
  | typeof RESOURCE_TRACKING_JOB_STATION_TABLE_PAGE_SIZE
  | typeof RESOURCE_TRACKING_JOB_STATION_TABLE_SORTING
  | typeof RESOURCE_TRACKING_JOB_STATION_DE_TABLE_COLUMN_WIDTH
  | typeof RESOURCE_TRACKING_JOB_STATION_DE_TABLE_SORTING
  | typeof RESOURCE_TRACKING_JOB_STATION_PPE_TABLE_COLUMN_WIDTH
  | typeof RESOURCE_TRACKING_JOB_STATION_PPE_TABLE_SORTING
  | typeof SCORECARD_ENABLED
  | typeof SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_COLUMN_ORDER
  | typeof SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_COLUMN_WIDTHS
  | typeof SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_HIDDEN_COLUMNS
  | typeof SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_PAGE_SIZE
  | typeof SUPPORT_GROUP_DOWNTIME_ESCALATION_TABLE_SORTING
  | typeof SUPPORT_HIERARCHY_MCELL_TABLE_COLUMN_ORDER
  | typeof SUPPORT_HIERARCHY_MCELL_TABLE_COLUMN_WIDTHS
  | typeof SUPPORT_HIERARCHY_MCELL_TABLE_HIDDEN_COLUMNS
  | typeof SUPPORT_HIERARCHY_MCELL_TABLE_SORTING
  | typeof SUPPORT_GROUP_TABLE_COLUMN_ORDER
  | typeof SUPPORT_GROUP_TABLE_COLUMN_WIDTHS
  | typeof SUPPORT_GROUP_TABLE_HIDDEN_COLUMNS
  | typeof SUPPORT_GROUP_TABLE_PAGE_SIZE
  | typeof SUPPORT_GROUP_TABLE_SORTING
  | typeof SUPPORT_GROUP_MEMBERS_TABLE_COLUMN_ORDER
  | typeof SUPPORT_GROUP_MEMBERS_TABLE_COLUMN_WIDTHS
  | typeof SUPPORT_GROUP_MEMBERS_TABLE_HIDDEN_COLUMNS
  | typeof SUPPORT_GROUP_MEMBERS_TABLE_PAGE_SIZE
  | typeof SUPPORT_GROUP_MEMBERS_TABLE_SORTING
  | typeof SUPPORT_HIERARCHY_ENABLED
  | typeof SUPPORT_HIERARCHY_SUPPORT_GROUP_SELECTED_ID
  | typeof SUPPORT_HIERARCHY_TABLE_COLUMN_ORDER
  | typeof SUPPORT_HIERARCHY_TABLE_COLUMN_WIDTHS
  | typeof SUPPORT_HIERARCHY_TABLE_HIDDEN_COLUMNS
  | typeof SUPPORT_HIERARCHY_TABLE_PAGE_SIZE
  | typeof SUPPORT_HIERARCHY_TABLE_SORTING
  | typeof SUPPORT_HIERARCHY_MCELL_TABLE_FILTER_SELECTIONS
  | typeof SCHEDULING_TOOL_CAPACITY_UTILIZATION_SELECTED_GROUPS
  | typeof SCHEDULING_TOOL_CAPACITY_UTILIZATION_SELECTED_PRODUCING_LOCATIONS
  | typeof SCHEDULING_TOOL_ENABLED
  | typeof SCHEDULING_TOOL_PRODUCING_LOCATION_METRIC_SELECTED
  | typeof SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_PAGE_SIZE
  | typeof SCHEDULING_TOOL_PRODUCTION_LOCATION_CONFIG_TABLE_SORTING
  | typeof SCHEDULING_TOOL_M_CELL_MAP_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_M_CELL_MAP_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_M_CELL_MAP_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_M_CELL_MAP_TABLE_SORTING
  | typeof SCHEDULING_TOOL_M_CELL_MAP_TABLE_PAGE_SIZE
  | typeof SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_PAGE_SIZE
  | typeof SCHEDULING_TOOL_M_CELL_GUARDRAIL_TABLE_SORTING
  | typeof SCHEDULING_TOOL_SALES_ORDER_TYPE_FILTER_SELECTIONS
  | typeof SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TYPE_FILTER_SELECTIONS
  | typeof SCHEDULING_TOOL_HARD_HOLD_TYPE_FILTER_SELECTIONS
  | typeof SCHEDULING_TOOL_SALES_ORDER_TYPE_SELECTED
  | typeof SCHEDULING_TOOL_SALES_ORDER_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_SALES_ORDER_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_SALES_ORDER_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_SALES_ORDER_TABLE_SORTING
  | typeof SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_SORTING
  | typeof SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_SALES_ORDER_LINE_ITEM_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_CHANGE_LOG_GLOBAL_MODE
  | typeof SCHEDULING_TOOL_CHANGE_LOG_ACCORDION_EXPANDED
  | typeof SCHEDULING_TOOL_CHANGE_LOG_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_CHANGE_LOG_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_CHANGE_LOG_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_CHANGE_LOG_TABLE_PAGE_SIZE
  | typeof SCHEDULING_TOOL_CHANGE_LOG_TABLE_TABLE_SORTING
  | typeof SCHEDULING_TOOL_SHIFT_SELECTED
  | typeof SCHEDULING_TOOL_EMPLOYEE_UTILIZATION_DATA_SORTING
  | typeof SCHEDULING_TOOL_CAPACITY_UTILIZATION_DESCRIPTION_CHECKED
  | typeof SCHEDULING_TOOL_HARD_HOLD_TABLE_HIDDEN_COLUMNS
  | typeof SCHEDULING_TOOL_HARD_HOLD_TABLE_SORTING
  | typeof SCHEDULING_TOOL_HARD_HOLD_TABLE_COLUMN_ORDER
  | typeof SCHEDULING_TOOL_HARD_HOLD_TABLE_COLUMN_WIDTHS
  | typeof SCHEDULING_TOOL_ORDER_SEARCH_HIDE_PROCESSED_ORDERS
  | typeof LSW_SHEET_TABLE_COLUMN_ORDER
  | typeof LSW_SHEET_TABLE_COLUMN_WIDTHS
  | typeof LSW_SHEET_TABLE_HIDDEN_COLUMNS
  | typeof LSW_SHEET_TABLE_SORTING
  | typeof LSW_SHEET_TABLE_PAGE_SIZE
  | typeof LSW_TASK_TABLE_COLUMN_ORDER
  | typeof LSW_TASK_TABLE_COLUMN_WIDTHS
  | typeof LSW_TASK_TABLE_HIDDEN_COLUMNS
  | typeof LSW_TASK_TABLE_SORTING
  | typeof LSW_TASK_TABLE_PAGE_SIZE
  | typeof LSW_TASK_GROUP_TABLE_COLUMN_ORDER
  | typeof LSW_TASK_GROUP_TABLE_COLUMN_WIDTHS
  | typeof LSW_TASK_GROUP_TABLE_HIDDEN_COLUMNS
  | typeof LSW_TASK_GROUP_TABLE_SORTING
  | typeof LSW_TASK_GROUP_TABLE_PAGE_SIZE
  | typeof LSW_REPORT_TABLE_COLUMN_ORDER
  | typeof LSW_REPORT_TABLE_COLUMN_WIDTHS
  | typeof LSW_REPORT_TABLE_HIDDEN_COLUMNS
  | typeof LSW_REPORT_TABLE_SORTING
  | typeof LSW_REPORT_TABLE_PAGE_SIZE
  | typeof LSW_ENABLED;

export type UserSetting = {
  [key in UserSettingKey]?: string;
};
