import { Alert, Snackbar } from '@mui/material';
import { BannerLevel } from '../../dev/types';
import { useLocation } from 'react-router-dom';

type Props = {
  level: BannerLevel;
  message: string;
  locations?: string[];
};

/**
 * Renders a banner with a message.
 * @param level
 * @param message
 * @param locations
 * @constructor
 */
const Banner = ({ level, message, locations }: Props) => {
  const currentLocation = useLocation().pathname;
  if (
    locations &&
    locations.length > 0 &&
    !locations.includes(currentLocation)
  ) {
    return null;
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={true}
    >
      <Alert severity={level}>{message}</Alert>
    </Snackbar>
  );
};

export default Banner;
