import React, { lazy } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Navigation from '../common/Navigation';
import { Provider, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { getFontSize } from '../settings/utils';
import { useInitialSetup, usePageTracking } from './utils';
import Loading from '../common/Loading';
import { RootState, store } from '../../store';
import { hasPermission } from '../../auth';
import NotFoundPage from '../common/NotFoundPage';
import {
  getAbsoluteNavigationPaths,
  getRelativeNavigationPaths,
} from '../../utils/navigation';
import Home from '../home';
import { SnackbarProvider } from 'notistack';
import Banner from './components/Banner';
import { useGlobalSettings } from '../profile/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AlertProvider } from '../common/AlertContext';
import AlertNotification from '../common/AlertNotification';

// Do dynamic imports here so that these Common are
// only loaded when user navigates to them for the first time
const Settings = lazy(() => import('../settings'));
const Dashboard = lazy(() => import('../dashboard'));
const ResourceTracking = lazy(() => import('../resourceTracking'));
const ReportIssue = lazy(() => import('../downtime/components/ReportIssue'));
const IssueConfigurations = lazy(
  () => import('../downtime/components/IssueConfigurations'),
);
const CellBoard = lazy(() => import('../cellBoard'));
const SupportHierarchy = lazy(() => import('../supportHierarchy'));
const SchedulingTool = lazy(() => import('../schedulingTool'));
const Lsw = lazy(() => import('../lsw'));
const Coatings = lazy(() => import('../coatings'));
const ScoreBoard = lazy(
  () => import('../cellBoard/components/CellBoardSummaryPage'),
);
const Crewing = lazy(() => import('../crewing'));
const Configurations = lazy(() => import('../configuration'));
const Dev = lazy(() => import('../dev'));
const Emails = lazy(() => import('../email'));
const EmployeeMCellLoginLogoutPage = lazy(
  () => import('../mCellEmployee/components/EmployeeMCellLoginLogoutPage'),
);

// Create a new query client outside App component to avoid recreating it on every render
const queryClient = new QueryClient();

/**
 * Top level react component. Hosts all the providers and initializes
 * the application
 * @constructor
 */
export const App: React.FC = () => {
  useInitialSetup(store);
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <AlertProvider>
                  <AlertNotification />
                  <Router>
                    <UI />
                  </Router>
                </AlertProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </Provider>
  );
};

/**
 * Top level component that renders the UI when initialization is complete.
 * Otherwise, renders the main loading animation
 * @constructor
 */
export const UI = () => {
  const ready = useSelector((state: RootState) => state.app.ready);
  const { bannerInfo } = useGlobalSettings();
  usePageTracking(); // used to manually implement page tracking for applicationInsights
  if (ready) {
    const canReadDashboards = hasPermission(['Dashboard.Read']);
    const canReadSupportHierarchy = hasPermission([
      'SupportHierarchy.HierarchyRead',
    ]);
    const canReadCapacity = hasPermission(['ResourceTracking.CapacityRead']);
    const isDeveloper = hasPermission(['Developer.All']);
    const canReadSchedulingTool = hasPermission(['SchedulingTool.Read']);
    const canReadEmail = hasPermission(['Email.Read']);
    const canViewOrEditIssueConfig = hasPermission([
      'Downtime.ReasonEdit',
      'Downtime.ReasonSupportGroupRead',
    ]);

    const {
      homePath,
      reportIssuePath,
      issueConfigurationAltPath,
      dashboardPath,
      crewingPath,
      cellBoardPath,
      coatingsPath,
      cellboardSummaryPath,
      supportHierarchyPath,
      schedulingToolPath,
      lswPath,
      resourceTrackingPath,
      settingsPath,
      configurationPath,
      devPath,
      emailsPath,
      cellLoginPath,
    } = getRelativeNavigationPaths();

    const { homePath: homePathAbsolute } = getAbsoluteNavigationPaths();

    return (
      <>
        <Routes>
          <Route path={'/*'} element={<Navigation />}>
            <Route index element={<Navigate to={homePathAbsolute} />} />
            <Route path={homePath} element={<Home />} />
            <Route path={reportIssuePath} element={<ReportIssue />} />
            {canViewOrEditIssueConfig && (
              <Route
                path={issueConfigurationAltPath}
                element={<IssueConfigurations />}
              />
            )}
            {canReadDashboards && (
              <Route path={dashboardPath} element={<Dashboard />} />
            )}
            {canReadCapacity && (
              <Route path={crewingPath} element={<Crewing />} />
            )}
            <Route path={cellBoardPath} element={<CellBoard />} />
            <Route path={coatingsPath} element={<Coatings />} />
            <Route path={cellboardSummaryPath} element={<ScoreBoard />} />
            {canReadSupportHierarchy && (
              <Route
                path={supportHierarchyPath}
                element={<SupportHierarchy />}
              />
            )}
            {canReadSchedulingTool && (
              <Route path={schedulingToolPath} element={<SchedulingTool />} />
            )}
            <Route path={lswPath} element={<Lsw />} />
            <Route path={resourceTrackingPath} element={<ResourceTracking />} />
            <Route path={settingsPath} element={<Settings />} />
            <Route path={configurationPath} element={<Configurations />} />
            {canReadEmail && <Route path={emailsPath} element={<Emails />} />}
            <Route
              path={cellLoginPath}
              element={<EmployeeMCellLoginLogoutPage />}
            />
            {isDeveloper && <Route path={devPath} element={<Dev />} />}
            <Route path={'*'} element={<NotFoundPage />} />
          </Route>
        </Routes>
        {bannerInfo && bannerInfo.level && bannerInfo.message && (
          <Banner
            level={bannerInfo.level}
            message={bannerInfo.message}
            locations={bannerInfo.locations}
          />
        )}
      </>
    );
  }
  // We want to use <MainLoading /> here but there seems to be an issue with it in chrome/edge.
  // To replicate the MainLoading issue, open the Report Issue page, clear localstorage
  // with `localstorage.clear()`, refresh page ONLY ONCE, you will not be able to click
  // to focus inside any text field until you refresh the page again.
  return <Loading />;
};

export const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: '4px 4px 0px 0px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#fec609',
    },
    secondary: {
      main: grey[900],
    },
    text: {
      secondary: 'rgba(0,0,0,0.75)',
    },
    background: {
      default: '#FAFAFA',
    },
  },
  typography: {
    fontSize: getFontSize().value,
  },
});
