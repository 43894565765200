import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Typography,
  Box,
  IconButton,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAccount, logout } from '../../../auth';
import { SettingsIcon } from './../../../utils/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { connectionStateToColor } from '../utils';
import { LogoutIcon } from '../../settings/icons';

/**
 * Displays user info and menu options.
 * Includes settings and logout options.
 * @constructor
 */
const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const account = getAccount();
  const name = account?.name ?? '';
  const email = account?.username ?? '';
  const connectionState = useSelector(
    (state: RootState) => state.app.connectionState,
  );

  /**
   * Handles the opening of the menu.
   * @param event - The mouse event triggered by clicking the button.
   */
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  /**
   * Handles the closing of the menu.
   */
  const handleSettings = () => {
    handleClose();
    navigate('/settings');
  };

  /**
   * Navigates to the settings page.
   */
  const handleLogout = () => {
    handleClose();
    logout();
  };

  const connectionColor = connectionStateToColor(connectionState);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          justifyContent: 'flex-start',
          backgroundColor: 'rgba(0,0,0,0.87)',
          '&:hover': { backgroundColor: '#333' },
          width: 275,
          height: 54,
          position: 'fixed',
          bottom: 0,
          borderRadius: 0,
        }}
      >
        <Box
          sx={{
            width: 16,
            height: 16,
            backgroundColor: connectionColor,
            borderRadius: '50%',
            ml: 2,
          }}
        ></Box>
        <Typography
          variant='body1'
          sx={{
            color: 'white',
            fontWeight: 500,
            ml: 2.5,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {name}
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              width: 250,
              ml: 2,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ px: 2, py: 1.5 }}>
          <Typography
            variant='subtitle1'
            fontWeight='bold'
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {email}
          </Typography>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <MenuItem onClick={handleSettings}>
          <SettingsIcon sx={{ mr: 1 }} />
          Settings
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon fontSize='small' sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
